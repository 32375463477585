@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

body {
  padding: 0px;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
* {
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}
#root {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: Roboto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tvpc-m-land,
.tvpc-m-port {
  height: calc(100% - 30px);
  width: calc(100% - 30px);
  position: relative;
  display: flex;
  flex-direction: column;
}
.tvpc-m-port .content {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 640px;
  max-height: 900px;
  width: 100%;
  height: 100%;
  margin: auto;
}
.tvpc-m-land .content {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 900px;
  max-height: 640px;
  width: 100%;
  height: 100%;
  margin: auto;
}
.top-row {
  width: 100%;
  font-family: Roboto;
  font-size: 17px;
  font-weight: 700;
  min-height: 30px;
}

.tvpc-m-port .top-row {
  text-align: center;
}
.tvpc-m-land .top-row {
  text-align: left;
}
.tvpc-m-port .mid-row {
  min-height: 30px;
  height: 85%;
  width: 100%;
  display: flex;
  justify-content: stretch;
  margin-top: 10px;
  flex-direction: column;
}
.tvpc-m-land .mid-row {
  min-height: 30px;
  height: 85%;
  width: 100%;
  display: flex;
  justify-content: stretch;
  margin-top: 10px;
  flex-direction: row;
}
.tvpc-m-land .top-midrow {
  display: flex;
  flex-direction: column;
  width: 55%;
  min-height: 50px;
}
.tvpc-m-port .top-midrow {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 80px;
  /* background-color: aquamarine; */
}
.tvpc-m-land .btm-midrow {
  display: flex;
  flex-direction: column;
  width: 45%;
  min-height: 50px;
}
.tvpc-m-port .btm-midrow {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 80px;
}

.tvpc-m-port .top-midrow .div1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tvpc-m-land .top-midrow .div1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.tvpc-m-port .top-midrow .div2 {
  display: flex;
  align-items: start;
  justify-content: center;
  height: 25vh;
}
.tvpc-m-land .top-midrow .div2 {
  display: flex;
  align-items: start;
  justify-content: center;
  height: 60vh;
}
.tvpc-m-port .top-midrow .div2 > img {
  height: 20vh;
  margin: auto;
}
.tvpc-m-land .top-midrow .div2 > img {
  height: 40vh;
  margin: auto;
}
.tvpc-m-port .btm-midrow .div1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tvpc-m-land .btm-midrow .div1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.tvpc-m-port .btm-midrow .div2 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35vh;
}
.tvpc-m-land .btm-midrow .div2 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 53vh;
}
.btn {
  background-color: #2563eb;
  color: #fff;
  padding: 8px 30px;
  width: 120px;
  border-radius: 8px;
  font-size: 16px;
  border: solid 1px #1350d4;
}
.pagenumber {
  min-width: 50px;
  height: 50px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2563eb;
  color: #fff;
  font-family: Roboto;
  font-size: 21px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.40799999237060547px;
  text-align: center;
}
.runningtext {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 50px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.40799999237060547px;
  text-align: center;
}
.runningtext2 {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 50px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.40799999237060547px;
  text-align: center;
}

.tvpc-m-port .btm-row {
  min-height: 30px;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -30px;
}
.tvpc-m-land .btm-row {
  min-height: 30px;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
}

.tvpc-m-port .mid-row,
.tvpc-m-port .btm-row {
  flex-direction: column;
}

.tvpc-m-land .mid-row,
.tvpc-m-land .btm-row {
  flex-direction: row;
}
/*  */
/*  */
.tvpc-m-port .btm-midrow .div2 .videoHolder {
  height: 35vh;
  width: 53vh;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.tvpc-m-land .btm-midrow .div2 .videoHolder {
  height: 40vh;
  width: 60vh;
  margin: auto;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.videoPlayer {
  width: 120%;
}

.tvpc-m-land .cameraView .content,
.tvpc-m-port .cameraView .content {
  overflow: hidden;
}
.tvpc-m-land .cameraView {
  flex-direction: row;
  max-height: 100%;
  display: flex;
}
.tvpc-m-port .cameraView {
  flex-direction: column;
  max-height: 100%;
  display: flex;
}

.tvpc-m-land .cameraView .first-row {
  order: 1;
  display: flex;
  flex-direction: column;
  width: 38%;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
  padding: 15px;
}
.tvpc-m-port .cameraView .first-row {
  order: 2;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 30%;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.tvpc-m-land .cameraView .last-row {
  order: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: hidden;
  margin-bottom: 15px;
}
.tvpc-m-land .cameraView .last-row video {
  height: 90vh;
  margin: auto;
}
.tvpc-m-port .cameraView .last-row {
  order: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60%;
  overflow: hidden;
  min-height: 60vh;
}

.tvpc-m-land .cameraView .first-row .titlepart {
  font-family: Roboto;
  font-size: 23px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.40799999237060547px;
  text-align: left;
  white-space: nowrap;
  width: 100%;
  display: flex;
  color: #cbd5e1;
}
.tvpc-m-land .cameraView .first-row .titlepart span {
  color: #cbd5e1;
}
.tvpc-m-port .cameraView .first-row .titlepart {
  font-family: Roboto;
  font-size: 23px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.40799999237060547px;
  text-align: left;
  color: #cbd5e1;
}

.tvpc-m-land .cameraView .first-row .listpart {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  width: 100%;
  margin-left: -8px;
}
.tvpc-m-land .cameraView .first-row .listpart li {
  list-style: none;
  margin: 5px;
}
.tvpc-m-port .cameraView .first-row .listpart li {
  list-style: none;
  margin: 5px;
}
.tvpc-m-port .cameraView .first-row .listpart {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.40799999237060547px;
  text-align: left;
}
.tvpc-m-port .cameraView .last-row video {
  height: 60vh;
}
.errorScreen,
.endScreen {
  width: 100%;
  height: 100%;
  display: flex;
  color: #fff;
}

.tvpc-m-port .errorScreen,
.tvpc-m-port .endScreen {
  flex-direction: column;
}
.tvpc-m-land .errorScreen,
.tvpc-m-land .endScreen {
  flex-direction: row;
}
.tvpc-m-port .endScreen .imageArea {
  height: 45%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.tvpc-m-land .endScreen .imageArea {
  height: 100%;
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.tvpc-m-port .endScreen .textArea {
  height: 45%;
  width: 55%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: auto;
}
@media (max-width: 429px) {
  .tvpc-m-port .endScreen .textArea {
    height: 55%;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin: auto;
  }
}

.tvpc-m-land .endScreen .textArea {
  height: 50%;
  width: 55%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: auto;
}
.endScreen .imageArea .title {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.40799999237060547px;
  text-align: center;
}
.tvpc-m-land .endScreen .imageArea .title {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.40799999237060547px;
  text-align: center;
}

.textArea1 {
  margin-top: 15px;
}
.tvpc-m-land .errorScreen .errortext,
.tvpc-m-land .errorScreen .imageArea {
  width: 50%;
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
}
.tvpc-m-land .errorScreen .errortext {
  width: 50%;
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 15px;
  margin-left: 15px;
}
.tvpc-m-port .errorScreen .imageArea {
  width: 100%;
  height: 43%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.tvpc-m-port .errorScreen .errortext {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.tvpc-m-land .errorScreen .errortext .scrollable {
  width: 85%;
  min-height: 150px;
  height: 50%;
  margin: auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: #1e293b;
}

.errortext .scrollable li {
  line-height: 28px;
  text-align: left;
}

.tvpc-m-port .errorScreen .errortext .scrollable {
  min-width: 264px;
  width: 80%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex-flow: column;
  flex-grow: 1;
  background: #505964;
  padding: 15px;
}

.loader {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.landarea1 {
  margin-bottom: 15px;
}
.landarea2 {
  margin-top: 15px;
}
